<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="3" class="">
            <label>Mostrar</label>
            <v-select v-model="tableSettings.perPage" :options="perPageOptions" :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>registros</label>
          </b-col>
          <b-col cols="12" md="9">
            <div class="d-flex align-items-center justify-content-end">
              <b-col md="4" class="offset-8">
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="tableSettings.searchQuery" type="search" placeholder="Buscar" @input="fetchList" />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Borrar
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-button variant="primary" class="btn-icon rounded-circle mr-1" @click="newCotization()">
                <feather-icon icon="PlusIcon" />
              </b-button>
            </div>
          </b-col>
        </b-row>


      </div>

      <b-table ref="refRoleListTable" class="position-relative" :items="dataTable" responsive :fields="tableColumns"
        primary-key="id" :sort-by.sync="tableSettings.sortBy" show-empty empty-text="No se encontraron datos"
        :sort-desc.sync="tableSettings.sortDesc" :filter="filter" :filter-included-fields="filterOn"
        @filtered="onFiltered">

        <template #cell(status)="data" v-if="permissions.includes('vouchers.status')">
          <div class="text-nowrap">
            <b-form-select v-model="data.item.status" :options="status"
              @input="changeStatusVoucher(data.item.voucher_id, data.item.status)" />
          </div>
        </template>
        <template #cell(status)="data" v-else>
          <div class="text-nowrap">
            <b-badge variant="primary" class="badge-glow">
              {{ data.item.status1 }}
            </b-badge>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div v-if="data.item.status != 5">
            <b-button class="btn-icon rounded-circle" @click="editVoucher(data.item.cotization.id, 'voucher')"
              v-b-tooltip.hover.v-primary title="Editar" variant="primary">
              <feather-icon icon="EditIcon" />
            </b-button>
            <!-- <b-button class="btn-icon rounded-circle" @click="openFormStatusModal(data.item)" v-b-tooltip.hover.v-primary
            title="Cambiar estado" variant="info">
            <feather-icon icon="RotateCcwIcon" />
          </b-button> -->
            <!-- <form :action="'https://axis-backend.test/generate-pdf-cotization/' + data.item.id" method="get" target="_blank"
            rel="noopener noreferrer" style="display: contents;"> -->
            <!-- mio -->
            <!-- <form :action="'https://axis.api.desarrollo.com.co/generate-pdf-voucher/' + data.item.voucher_id" method="get"
              target="_blank" rel="noopener noreferrer" style="display: contents;">
              <input type="hidden" name="token" :value="csrf" />
              <b-button variant="danger" class="btn-icon rounded-circle" type="submit" v-b-tooltip.hover.v-primary
                title="Generar Pdf Vaucher">
                <feather-icon icon="ClipboardIcon" />
              </b-button>
            </form> -->


            <b-button variant="danger" class="btn-icon rounded-circle" v-b-tooltip.hover.v-primary
              @click="modalVoucher.status = true, modalVoucher.voucher_id = data.item.voucher_id"
              title="Generar Pdf Voucher">
              <feather-icon icon="ClipboardIcon" />
            </b-button>
            <b-button variant="success" class="btn-icon rounded-circle" @click="asegurate(data.item.voucher_id)"
              v-b-tooltip.hover.v-primary title="Aseguradores">
              <feather-icon icon="LockIcon" />
            </b-button>

            <b-button variant="danger" class="btn-icon rounded-circle" @click="deleteAgency(data.item)"
              v-b-tooltip.hover.v-primary title="Eliminar">
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Viendo del {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }}
              registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination v-model="tableSettings.page" :total-rows="totalRows" :per-page="tableSettings.perPage"
              first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>
    <validation-observer ref="formStatusChangeValidation">
      <b-modal id="formStatus" ref="formStatus" v-model="formStatus.modal" title="Cambiar estado" ok-title="Guardar"
        cancel-title="Cancelar" cancel-variant="outline-secondary" centered @show="resetFormStatusOpen"
        @hidden="resetFormStatus" @ok="handleOkFormStatus">
        <form ref="formStatus" @submit.stop.prevent="handleOkFormStatus">
          <b-row>
            <b-col col-md-6>
              <validation-provider #default="{ errors }" name="Estado" rules="required">
                <b-form-group label="Voucher" label-for="formStatusInput"
                  :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="id" v-model="formStatus.id" :state="errors.length > 0 ? false : null"
                    :class="errors.length > 0 ? 'is-invalid' : null" trim clearable disabled />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col col-md-6>
              <validation-provider #default="{ errors }" name="plan" rules="required">
                <b-form-group label="Plan" label-for="formStatusInput" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="plan" v-model="formStatus.plan" :state="errors.length > 0 ? false : null"
                    :class="errors.length > 0 ? 'is-invalid' : null" trim clearable disabled />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col col-md-12>
              <validation-provider #default="{ errors }" name="Estado" rules="required">
                <b-form-group label="Estado" label-for="estado" :class="errors.length > 0 ? 'is-invalid' : null">
                  <v-select id="estado" v-model="formStatus.status" :options="statusOptions" :reduce="val => val.id"
                    label="status" :state="errors.length > 0 ? false : null" trim clearable />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </form>
      </b-modal>
    </validation-observer>

    <b-modal centered v-model="modalVoucher.status" title="Selecciona una opción">
      <div class="mx-2 my-2">
        <b-button @click="openFirstURL" variant="primary">Voucher en español</b-button>
        <b-button class="float-right" @click="openSecondURL" variant="info">Voucher en ingles</b-button>
      </div>

      <template #modal-footer="{ ok, cancel, hide }">
        <b-button variant="danger" @click="cancel()">
          Cancelar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BFormGroup, BModal, BFormSelect, BInputGroup,
  BInputGroupAppend
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, email, digits, integer } from '@validations'
import es from 'vee-validate/dist/locale/es';

export default {
  name: 'cotizationHistory',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    VBTooltip,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend
  },
  data() {
    return {
      modalVoucher: {
        status: false,
        voucher_id: null
      },
      filter: null,
      filterOn: [],
      csrf: "Bearer " + localStorage.access_token,
      status1: null,
      permissions: [],
      status: [
        { value: 1, text: 'Emitido' },
        { value: 2, text: 'Activo' },
        { value: 3, text: 'Stand By' },
        { value: 4, text: 'Finalizado' },
        { value: 5, text: 'Anulado' },
      ],
      form: {
        code: null
      },
      csrf: "Bearer " + localStorage.access_token,
      shallShowEmailComposeModal: false,
      formDisabled: false,
      refRoleListTable: null,
      perPageOptions: [25, 50, 100],
      searchQuery: '',
      inputs: [
        { value: 'voucher_number', title: 'Numero vaucher' },
        { value: 'first_name', title: 'Nombre' },
        { value: 'last_name', title: 'Apellido' },
        { value: 'email', title: 'Correo' },
        { value: 'destiny', title: 'Destino' },
        { value: 'birthday', title: 'Fecha de naciemiento' },
        { value: 'document', title: 'Numero de documento' },
        { value: 'passport', title: 'Pasaporte' },
        { value: 'phone', title: 'Telefono' },
        { value: 'status', title: 'Estado' },
      ],
      typeSearch: [
        { value: 'LIKE', title: 'Igual' },
        { value: 'NOT LIKE', title: 'No es igual' },
        { value: '>', title: 'Mayor que' },
        { value: '<', title: 'Menor que' }
      ],
      tableColumns: [
        { key: 'voucher_number', label: 'No. Vaucher', sortable: true },
        { key: 'first_name', label: 'Nombre', sortable: true },
        { key: 'last_name', label: 'Apellido', sortable: true },
        { key: 'email', label: 'Correo' },
        { key: 'destiny', label: 'Destino' },
        { key: 'status', label: 'Estado', sortable: true },
        { key: 'actions', label: 'acciones' },
      ],
      sortBy: 'voucher_number',
      isSortDirDesc: true,
      totalRows: 0,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      dataTable: [],
      showLoadingTable: false,
      tableSettings: {
        filter: this.$route.params && this.$route.params.id,
        searchQuery: '',
        sortState: true,
        input: '',
        typeSearch: '',
        perPage: 25,
        page: 1,
        sortBy: 'voucher_number',
        sortDesc: true,
      },
      formDataEdit: {},
      formStatus: {
        modal: false,
        id: null,
        plan: '',
        status: ''
      },
      statusOptions: [],
      idVoucher: null
    }
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  watch: {
    "tableSettings.sortBy": {
      handler(val) {
        this.fetchList()
      },
    },
    "tableSettings.sortDesc": {
      handler(val) {
        this.fetchList()
      },
    },
    "tableSettings.perPage": {
      handler(val) {
        this.fetchList()
      },
    },
    // "tableSettings.searchQuery": {
    //   handler(val) {
    //     this.fetchList()
    //   },
    // },
    "tableSettings.page": {
      handler(val) {
        this.fetchList()
      },
    },
  },
  created() {
    this.fetchList(0)
    this.getVoucherStatus()
    this.userData = JSON.parse(localStorage.getItem('userData'))
    this.permissions = this.userData.permissions
  },
  methods: {
    openFirstURL() {
      var token = 'Bearer ' + localStorage.getItem('access_token')
      window.open('https://axis.api.desarrollo.com.co/generate-pdf-voucher/' + this.modalVoucher.voucher_id + '/1?token=' + token, '_blank');
      this.modalVoucher.status = false;
      this.modalVoucher.voucher_id = null;
    },
    openSecondURL() {
      var token = 'Bearer ' + localStorage.getItem('access_token')
      window.open('https://axis.api.desarrollo.com.co/generate-pdf-voucher/' + this.modalVoucher.voucher_id + '/2?token=' + token, '_blank');
      this.modalVoucher.status = false;
      this.modalVoucher.voucher_id = null;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    asegurate(id) {
      this.$router.push('voucher/asegurate/' + id)
    },
    changeStatusVoucher(idVoucher, idStatus) {
      this.$http.post('/voucher/changeStatus/' + idVoucher, { 'status': idStatus })
        .then((response) => {
          if (response.data.code == 200) {
            this.$swal({
              icon: "success",
              title: response.data.message
            });
          }
          if (response.data.code == 500) {
            this.$swal({
              icon: "error",
              title: response.data.message
            });
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    changeStatusVoucherApi(vouchers) {
      this.$http.post('/voucher/changeStatusApi', { vouchers: vouchers })
        .then((response) => {
          this.fetchList(1)
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },

    newCotization() {
      this.$router.push('/cotizations/new-cotization');
    },
    historic() {
      this.$router.push('/historic');
    },
    fetchList(type) {
      this.showLoadingTable = true
      this.$http.get('/voucher/list', { params: this.tableSettings }).then((response) => {
        this.dataTable = response.data.vouchers
        this.totalRows = response.data.total
        this.dataMetaCounter()
        if (type != undefined && type == 0) {
          this.changeStatusVoucherApi(response.data.vouchers)
        }
        this.showLoadingTable = false
      })
    },
    dataMetaCounter() {
      const localItemsCount = this.dataTable.length
      this.dataMeta.from = this.tableSettings.perPage * (this.tableSettings.page - 1) + (localItemsCount ? 1 : 0)
      this.dataMeta.to = this.tableSettings.perPage * (this.tableSettings.page - 1) + localItemsCount
      this.dataMeta.of = this.totalRows
    },
    detailRole(role) {
      this.formDisabled = true
      this.formDataEdit = role
    },
    editVoucher(id, voucher) {
      this.formDataEdit = id
      this.$router.push({
        name: 'new-cotization',
        params: { id: id, voucher: voucher }
      });
    },
    deleteAgency(item) {
      this.$swal({
        title: `Esta séguro de eliminar la agencia?`,
        text: "Este proceso no se puede revertir!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, Eliminar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.post('/voucher/delete-traveller/' + item.id).then(() => {
            this.fetchList()
            this.$swal({
              icon: 'success',
              title: 'Agencia eliminado!',
              text: 'El rol ha sido eliminado.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Eliminación cancelada',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    searchData() {
      let url = ''
      if (this.tableSettings.input.value == 'voucher_number') {
        url = '/voucher/search-voucher/list'
      } else {
        url = '/voucher/search-voucher'
      }
      this.$http.get(url, { params: this.tableSettings }).then((response) => {
        this.dataTable = response.data.vouchers
        this.totalRows = response.data.total
        this.dataMetaCounter()
        this.showLoadingTable = false
      })
    },
    getVoucherStatus() {
      this.$http.get('/voucher/get-voucher-status').then((response) => {
        this.statusOptions = response.data.voucherStatus
      })
    },
    openFormStatusModal(item) {
      this.formStatus.modal = true
      this.formStatus.id = item.id
      this.formStatus.plan = item.plan
      this.formStatus.status = item.status
    },
    resetFormStatus() {
      this.$refs.formStatusChangeValidation.reset()
      this.formStatus = {
        modal: false,
        id: null,
        plan: '',
        status: ''
      }
    },
    resetFormStatusOpen() {
      this.$refs.formStatusChangeValidation.reset()
    },
    handleOkFormStatus(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleOkFormStatusUpdate()
    },
    handleOkFormStatusUpdate() {
      localize('es', es)
      this.$refs.formStatusChangeValidation.validate().then(success => {
        if (success) {
          this.$http.post('voucher/update-voucher-status', this.formStatus).then((response) => {
            this.resetFormStatus()
            this.fetchList()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Cambio de estado`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `El cambio de estado del voucher fue realizado con éxito`,
              },
            })
            this.$nextTick(() => {
              this.$refs['formGenerateQuote'].toggle('#toggle-btn')
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
